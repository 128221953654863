import React from 'react';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  background: #ddd;
  padding: 1rem;

  h3 {
    font-size: 1rem;
    margin: 0 0 0.5rem;
  }

  ul {
    margin: 0 0 1rem;
    padding: 0;
  }

  li {
    font-size: 0.875rem;
    list-style: none;
    margin: 0 0 0.5rem;
    padding: 0;
    white-space: nowrap;
  }

  ul ul {
    margin: 0.5rem 0;
  }

  ul ul li {
    list-style: square;
    margin-left: 1rem;
  }
`;

export const Filters = (props) => {
  const { main, secondary } = props;

  return (
    <Wrapper>
      {main && (
        <div>
          <h3>{main.title}</h3>
          <ul>{main.links.map((l, index) => (
            <li key={`main-${l.id}-${index}`}>{l.label}</li>
          ))}
          </ul>
        </div>
      )}
      {secondary && (
        <div>
          <h3>{secondary.title}</h3>
          <ul>{secondary.links.map(l => (
            <li key={`secondary-${l.id}`}>
              {l.label}
              {l.links && (
                <ul>{l.links.map(sl => (
                  <li key={`secondary-sub-${sl.id}`}>{sl.label}</li>
                ))}
                </ul>
              )}
            </li>
          ))}
          </ul>
        </div>
      )}
    </Wrapper>
  );
}