import React from 'react';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  background: #eee;
  padding: 1rem;
`;

export const Results = (props) => {
  const { children } = props;

  return (<Wrapper>{children}</Wrapper>);
}