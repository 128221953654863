import React from "react"
// import { Link } from "gatsby"
import { Filters, Results, Main, Actions } from '../blocks';
import Layout from '../containers/layout';

const IndexPage = () => (
  <Layout page="products">
    <Filters
      main={{
        title: "Products",
        links: [
          { id: 'recent', label: 'Recent' },
          { id: 'popular', label: 'Popular' }
        ]
      }}
      secondary={{
        title: 'Categories',
        links: [
          {
            id: 'makeup',
            label: 'Makeup',
            links: [
              { id: 'makeup-eyeshadow1', label: 'Eye shadow 1' },
              { id: 'makeup-eyeshadow2', label: 'Eye shadow 2' },
              { id: 'makeup-eyeshadow3', label: 'Eye shadow 3' },
              { id: 'makeup-eyeshadow4', label: 'Eye shadow 4' },
              { id: 'makeup-eyeshadow5', label: 'Eye shadow 5' },
            ]
          }
        ]
      }}
    />
    <Results>results</Results>
    <Main>main</Main>
    <Actions>actions</Actions>
  </Layout>
)

export default IndexPage
