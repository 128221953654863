import React from 'react';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  overflow: auto;
  overscroll-behavior: contain;
  padding: 1rem;
`;

export const Main = (props) => {
  const { children } = props;

  return (<Wrapper>{children}</Wrapper>);
}