import React from "react";
// import Helmet from "react-helmet";
// import { Link } from "gatsby"
import { Container, Header } from '../blocks';

const Layout = (props) => (
  <Container>
    <Header page={props.page} />
    {props.children}
  </Container>
)

export default Layout
