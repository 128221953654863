import React from 'react';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 60px auto;
  grid-template-columns: 150px 270px auto 60px;
  height: 100vh;
  overflow: hidden;
  width: 100%;
`;

export const Container = (props) => {
  const { children } = props;

  return (
    <Wrapper>{children}</Wrapper>
  );
}