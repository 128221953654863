import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  align-items: center;
  background: #421770;
  color: #fff;
  display: flex;
  grid-column-start: 1;
  grid-column-end: 5;
  padding: 0;
`;

const Nav = styled.div`
  align-items: center;
  display: flex;
  margin: 0 0 0 1rem;
  padding: 0;
`;

const MainNav = styled(Nav)`
  flex: 1;
`;

const SecondaryNav = styled(Nav)``;

const NavItem = styled.div`
  list-style: none;
  margin: 0 1rem 0 0;
  padding: 0;
  border-bottom: ${p => (p.isActive ? '1px solid #fff' : '1px solid transparent')};

  a {
    color: #fff;
    text-decoration: none;
  }
`;

const Logo = styled.div`
  flex: 0 0 auto;
  margin: 0 1rem;
`;

const navigation = [
  { id: 'products', label: 'Products' },
  { id: 'orders', label: 'Orders' },
  { id: 'invoices', label: 'Invoices' },
  { id: 'inventory', label: 'Inventory' },
  { id: 'contacts', label: 'Contacts' },
];

export const Header = (props) => {
  const { page } = props;

  return (
    <Wrapper>
      <Logo>
        <svg width="115" height="17" viewBox="0 0 115 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 0.293365H9.45304V3.44107H3.15752V6.59859H7.87913V9.7463H3.15752V12.894H9.45304V16.0515H3.15752H0V0.293464V0.293365ZM10.7238 0.293365H21.7601L15.7285 12.8942H21.7601V16.0517H10.7238L16.7554 3.44087H10.7238V0.293165V0.293365ZM22.738 0.293365H26.3159L29.0334 5.28866L31.751 0.293365H35.3387L30.6072 8.98382V16.0515H27.4595V8.98382L22.738 0.293365ZM36.5118 6.58707e-05L44.1955 10.5378L51.8791 6.58707e-05V16.0514H48.7216V9.5507L44.1955 15.7973L39.6693 9.5507V16.0514H36.5118V6.58707e-05ZM58.8002 10.841H61.5862L60.1981 7.56618L58.8002 10.841ZM57.5098 13.6271L57.4903 13.6173C57.1384 14.4189 56.7767 15.2107 56.415 16.0123H53.0228L60.1688 0L67.3343 16.0123H63.9325C63.5805 15.2205 63.2286 14.4189 62.8767 13.6271H57.5099L57.5098 13.6271ZM68.5367 16.0514L68.5269 0.273344H73.6493C75.1645 0.273344 76.6602 0.801223 77.7453 1.88629C78.8304 2.97135 79.2702 3.98803 79.2702 5.50327V5.99205C79.2702 7.16515 78.9379 8.02543 78.2731 8.96388C77.6084 9.90234 77.0512 10.176 76.0149 10.5671L79.5732 16.0512H75.7216L71.6941 9.89263V16.0512H68.5366L68.5367 16.0514ZM71.6844 7.85948L73.9426 7.86925C74.5976 7.86925 74.93 7.70306 75.3894 7.24361C75.8488 6.78417 76.015 6.25628 76.015 5.60134C76.015 4.95615 75.8488 4.52604 75.3894 4.05677C74.9299 3.58749 74.1088 3.42135 73.4538 3.42135H71.6844V7.85947V7.85948ZM81.0396 0.293365H84.1971V6.27606L87.6675 0.293365H91.3138L86.6607 8.29953L92.0666 16.0515H88.2248L84.1973 10.2643V16.0515H81.0397V0.293464L81.0396 0.293365ZM93.2396 0.293365H102.693V3.44107H96.3971V6.59859H101.119V9.7463H96.3971V12.894H102.693V16.0515H96.3971H93.2396V0.293464V0.293365ZM107.903 0.293365H111.06H115V3.44107H111.06V16.0519H107.903V3.44107H103.963V0.293365H107.903Z" fill="white" />
        </svg>
      </Logo>
      <MainNav>
        {navigation.map(n => (
          <NavItem key={`nav-${n.id}`} isActive={n.id === page}>
            <Link to={`/${n.id}`}>{n.label}</Link>
          </NavItem>
        ))}
      </MainNav>
      <SecondaryNav>
        <NavItem>Account</NavItem>
      </SecondaryNav>
    </Wrapper>
  );
}